#multi-switch {
	background-color: #36393f;
	display: flex;
	flex-direction: row;
	width: fit-content;
	margin-left: auto;
}

#multi-switch-button-image {
	width: 20px;
	height: 20px;
	pointer-events: none;
}

@media screen and (max-width: 800px) {
	#multi-switch-button-image {
		width: 20px;
		height: 20px;
	}
}

@media screen and (max-width: 700px) {
	#multi-switch-button-image {
		width: 18px;
		height: 18px;
	}
}

@media screen and (max-width: 600px) {
	#multi-switch-button-image {
		width: 16px;
		height: 16px;
	}
}

@media screen and (max-width: 500px) {
	#multi-switch-button-image {
		width: 14px;
		height: 14px;
	}
}

@media screen and (max-width: 400px) {
	#multi-switch-button-image {
		width: 12px;
		height: 12px;
	}
}

.multi-switch-button {
	background-color: #36393f;
	border: none;
	border-top: 1px solid var(--border);
	border-bottom: 1px solid var(--border);
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 3px;
	cursor: pointer;
}

.multi-switch-button:focus {
	outline: none !important;
}

.left-button {
	border-left: 1px solid var(--border);
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.right-button {
	border-left: 1px solid var(--grey);
	border-right: 1px solid var(--border);
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.middle-button {
	border-left: 1px solid var(--grey);
}

.left-button.hover:hover + .middle-button {
	border-left: none;
}

.middle-button.hover:hover + .right-button {
	border-left: none;
}

.hover:hover {
	background-color: var(--grey);
}

.left-button.active {
	background-color: var(--green) !important;
}
.middle-button.active {
	background-color: var(--grey) !important;
}
.right-button.active {
	background-color: var(--red) !important;
}
