input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	appearance: textfield;
	-moz-appearance: textfield;
}

.time-input-container {
	margin-left: auto;
}

.time-input {
	background-color: var(--input-grey);
	color: var(--text);
	font-size: 1rem;
	border: 2px solid var(--border);
	border-radius: 10px;
	padding: 2px;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 10px;
	margin-right: 0;
	width: 100px;
	height: 25px;
}

.time-input:focus {
	border: 2px solid var(--bluple-hover);
	outline: none;
}

.time-input:hover {
	border: 2px solid var(--bluple-hover);
}

.time-input.invalid {
	border: 2px solid var(--red) !important;
}

.time-input.invalid:hover {
	border: 2px solid var(--red-hover) !important;
}

.time-input.invalid:focus {
	border: 2px solid var(--red-hover) !important;
}

@media screen and (max-width: 600px) {
	.time-input {
		font-size: 0.9rem;
		padding: 1px;
		padding-left: 9px;
		padding-right: 9px;
		width: 75px;
	}
}

@media screen and (max-width: 500px) {
	.time-input {
		font-size: 0.8rem;
		padding: 0;
		padding-left: 8px;
		padding-right: 8px;
		width: 75px;
	}
}

@media screen and (max-width: 400px) {
	.time-input {
		font-size: 0.7rem;
		padding: 0;
		padding-left: 7px;
		padding-right: 7px;
		width: 50px;
	}
}

.unit-select {
	background-color: var(--input-grey);
	color: var(--text);
	font-size: 1rem;
	border: 2px solid var(--border);
	border-radius: 10px;
	padding: 2px;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 10px;
	margin-right: 0;
	width: 90px;
	cursor: pointer;
}

.unit-select:focus {
	border: 2px solid var(--bluple-hover);
	outline: none;
}

.unit-select:hover {
	border: 2px solid var(--bluple-hover);
}

@media screen and (max-width: 600px) {
	.unit-select {
		font-size: 0.9rem;
		padding: 1px;
		padding-left: 9px;
		padding-right: 9px;
		width: 85px;
	}
}

@media screen and (max-width: 500px) {
	.unit-select {
		font-size: 0.8rem;
		padding: 0;
		padding-left: 8px;
		padding-right: 8px;
		width: 75px;
	}
}

@media screen and (max-width: 400px) {
	.unit-select {
		font-size: 0.7rem;
		padding: 0;
		padding-left: 7px;
		padding-right: 0;
		width: 75px;
	}
}
