.role-select {
	margin-left: auto;
	width: 400px;
}

@media screen and (max-width: 700px) {
	.role-select {
		width: 250px;
	}
}

@media screen and (max-width: 600px) {
	.role-select {
		width: 250px;
	}
}

@media screen and (max-width: 500px) {
	.role-select {
		width: 200px;
	}
}

@media screen and (max-width: 400px) {
	.role-select {
		width: 200px;
	}
}
