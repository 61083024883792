.regex-input {
	background-color: var(--input-grey);
	color: var(--text);
	font-size: 1rem;
	border: 2px solid var(--border);
	border-radius: 10px;
	padding: 2px;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: auto;
	margin-right: 25%;
	width: 300px;
	height: 25px;
}

.regex-input:focus {
	border: 2px solid var(--bluple-hover);
	outline: none;
}

.regex-input:hover {
	border: 2px solid var(--bluple-hover);
}

.regex-input.invalid {
	border: 2px solid var(--red) !important;
}

.regex-input.invalid:hover {
	border: 2px solid var(--red-hover) !important;
}

.regex-input.invalid:focus {
	border: 2px solid var(--red-hover) !important;
}

@media screen and (max-width: 800px) {
	.regex-input {
		margin-right: 20%;
	}
}

@media screen and (max-width: 700px) {
	.regex-input {
		margin-right: 10%;
		width: 250px;
	}
}

@media screen and (max-width: 600px) {
	.regex-input {
		font-size: 0.9rem;
		padding: 1px;
		padding-left: 9px;
		padding-right: 9px;
		margin-right: 5%;
		width: 200px;
	}
}

@media screen and (max-width: 500px) {
	.regex-input {
		font-size: 0.8rem;
		padding: 0;
		padding-left: 8px;
		padding-right: 8px;
		width: 175px;
	}
}

@media screen and (max-width: 400px) {
	.regex-input {
		font-size: 0.7rem;
		padding: 0;
		padding-left: 7px;
		padding-right: 7px;
		margin-right: 0;
		width: 150px;
	}
}
