#page {
	display: block;
	padding: 2%;
	padding-left: 10%;
	padding-right: 10%;
	flex-direction: column;
	text-align: left;

	color: var(--text);
}

h1 {
	font-size: 2rem;
}

#page a:link {
	color: var(--bluple);
}

#page a:visited {
	color: var(--bluple-muted);
}

#page a:hover {
	color: var(--bluple-hover);
}
