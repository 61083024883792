#footer {
	display: flex;
	margin-top: 20px;
	margin-right: 10px;
	margin-left: 10px;
	padding: 20px;
	border-radius: 20px;
	background-color: var(--background-grey);

	flex-direction: row;
}

#footer-left {
	margin-left: 0;
	margin-right: auto;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#footer-right {
	margin-left: auto;
	margin-right: 0;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#footer-branding {
	display: flex;
	flex-direction: row;
	align-items: center;
}

#footer-logo {
	width: 40px;
	height: 40px;
	border-radius: 100%;
}

#app-name-footer {
	color: var(--text);
	font-size: 1.8rem;
	text-decoration: none;
	margin-left: 7.5px;
}

#footer-copyright {
	margin: 0;
	color: var(--text);
	font-size: 1rem;
	padding: 5px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

#footer-link {
	color: var(--text);
	font-size: 1rem;
	padding: 5px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

@media screen and (max-width: 600px) {
	#footer-logo {
		width: 30px;
		height: 30px;
	}
	#app-name-footer {
		font-size: 1.5rem;
	}
	#footer-copyright {
		font-size: 0.8rem;
	}
	#footer-link {
		font-size: 0.8rem;
	}
}

@media screen and (max-width: 400px) {
	#footer-logo {
		width: 25px;
		height: 25px;
	}
	#app-name-footer {
		font-size: 1.2rem;
	}
	#footer-copyright {
		font-size: 0.7rem;
	}
	#footer-link {
		font-size: 0.7rem;
	}
}
