#header {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 10px;
	margin-left: 10px;
	padding: 20px;
	border-radius: 20px;
	background-color: var(--background-grey);
}

#header-left {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 20px;
	margin-right: auto;
}

#app-name-header {
	color: var(--text);
	font-size: 2rem;
	text-decoration: none;
	margin-left: 20px;
}

#app-logo-header {
	width: 50px;
	height: 50px;
	border-radius: 100%;
}

#header-right {
	margin-left: auto;
	margin-right: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.header-link {
	color: var(--text);
	font-size: 1.2rem;
	padding: 5px;
	padding-left: 7.5px;
	padding-right: 7.5px;
	text-decoration: none;
	border-radius: 5px;
}

.header-link:not(:last-child) {
	margin-right: 20px;
}

#invite {
	background-color: var(--bluple);
}

#invite:hover {
	background-color: var(--bluple-hover);
}

@media screen and (max-width: 750px) {
	#app-name-header {
		margin-left: 10px;
		font-size: 1.5rem;
	}

	.header-link {
		font-size: 1rem;
	}

	.header-link:not(:last-child) {
		margin-right: 10px;
	}
}

@media screen and (max-width: 600px) {
	#header {
		padding: 15px;
	}

	#header-left {
		margin-left: 10px;
	}

	#app-logo-header {
		width: 40px;
		height: 40px;
	}

	#app-name-header {
		margin-left: 10px;
		font-size: 1.5rem;
	}

	.header-link {
		font-size: 1rem;
	}

	.header-link:not(:last-child) {
		margin-right: 10px;
	}
}

@media screen and (max-width: 550px) {
	#app-logo-header {
		width: 30px;
		height: 30px;
	}

	#app-name-header {
		margin-left: 10px;
		font-size: 1.2rem;
	}

	.header-link {
		font-size: 0.8rem;
	}

	.header-link:not(:last-child) {
		margin-right: 2px;
	}
}

@media screen and (max-width: 450px) {
	#app-logo-header {
		width: 25px;
		height: 25px;
	}

	#app-name-header {
		margin-left: 10px;
		font-size: 1rem;
	}

	.header-link {
		font-size: 0.7rem;
	}

	.header-link:not(:last-child) {
		margin-right: 0;
	}
}
