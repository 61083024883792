.row {
	flex-direction: row;
}

.config-container {
	margin-top: 20px;
}

.collapsed {
	display: none !important;
}

.input-container {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 10px;
}

.input-row-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-top: 10px;
}

label {
	color: #fff;
	font-size: 1.5rem;
	margin-left: 20px;
	margin-right: 30px;
}

@media screen and (max-width: 800px) {
	label {
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 600px) {
	label {
		font-size: 1rem;
	}
}

@media screen and (max-width: 450px) {
	label {
		font-size: 0.8rem;
	}
}

.show-explanation {
	font-size: 0.75rem;
	color: var(--bluple);
	cursor: pointer;
}

.show-explanation:hover {
	color: var(--bluple-hover);
}

.hide {
	display: none;
}

.flexible-align {
	margin-left: auto;
	margin-right: 30%;
}

@media screen and (min-width: 1000px) {
	.flexible-align {
		margin-right: 35%;
	}
}

@media screen and (max-width: 900px) {
	.flexible-align {
		margin-right: 30%;
	}
}

@media screen and (max-width: 800px) {
	.flexible-align {
		margin-right: 25%;
	}
}

@media screen and (max-width: 700px) {
	.flexible-align {
		margin-right: 10%;
	}
}

@media screen and (max-width: 600px) {
	.flexible-align {
		margin-right: 5%;
	}
}

@media screen and (max-width: 500px) {
	.flexible-align {
		margin-right: 5%;
	}
}

@media screen and (max-width: 400px) {
	.flexible-align {
		margin-right: 0;
	}
}
