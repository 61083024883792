body {
	font-family: 'Roboto', sans-serif;
	background-color: #212121;
}

:root {
	--background: #212121;
	--background-grey: #36393f;
	--border: #1e2124;
	--text: #fff;
	--grey: #4f545c;
	--input-grey: #424549;
	--green: #3ba55d;
	--green-hover: #2e8b4a;
	--green-disabled: #22743bad;
	--red: #ed4245;
	--red-hover: #d43a3c;
	--bluple: #7289da;
	--bluple-hover: #5f73bc;
}

#setup-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 5%;
	margin-top: 2%;
}

@media screen and (max-width: 600px) {
	#setup-container {
		margin: 7.5%;
		margin-top: 3%;
	}
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: #36393f;
	border-radius: 10px;
	padding: 20px;
}

.visualizeBorder {
	border: 1px solid var(--bluple) !important;
}

.hover:hover {
	border: 1px solid var(--bluple) !important;
}

button:disabled {
	cursor: default !important;
	pointer-events: none !important;
}

input:disabled {
	cursor: default !important;
	pointer-events: none !important;
}

select:disabled {
	cursor: default !important;
	pointer-events: none !important;
}

#page {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
	margin-right: 10px;
	margin-left: 10px;
	padding: 20px;
	border-radius: 20px;
	background-color: var(--background-grey);
}