#logo {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	margin-right: 10px;
}

@media screen and (max-width: 600px) {
	#logo {
		width: 40px;
		height: 40px;
	}
}

@media screen and (max-width: 500px) {
	#logo {
		width: 30px;
		height: 30px;
	}
}

@media screen and (max-width: 400px) {
	#logo {
		width: 25px;
		height: 25px;
	}
}

#setup-heading {
	font-size: 2rem;
	color: var(--text);
	margin: 0;
	margin-left: 10px;
	margin-right: auto;
}

@media screen and (max-width: 600px) {
	#setup-heading {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 500px) {
	#setup-heading {
		font-size: 1.25rem;
		margin-left: 7.5px;
	}
}

@media screen and (max-width: 400px) {
	#setup-heading {
		font-size: 1rem;
		margin-left: 5px;
	}
}

#new-button {
	background-color: var(--bluple);
	color: var(--text);
	align-content: center;
	border: none;
	border-radius: 10px;
	padding: 7.5px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 1.5rem;
	margin-left: auto;
	margin-right: 0;
	cursor: pointer;
}

#new-button:hover.enabled {
	background-color: var(--bluple-hover);
}

#new-button.disabled {
	background-color: var(--grey);
	color: var(--bluple);
	border: 1px solid var(--bluple);
	cursor: default;
}

@media screen and (max-width: 600px) {
	#new-button {
		font-size: 1.25rem;
	}
}

@media screen and (max-width: 500px) {
	#new-button {
		font-size: 1rem;
		border-radius: 7.5px;
		padding: 5px;
		padding-left: 7.5px;
		padding-right: 7.5px;
	}
}

@media screen and (max-width: 400px) {
	#new-button {
		font-size: 0.75rem;
		border-radius: 5px;
		padding: 2.5px;
		padding-left: 5px;
		padding-right: 5px;
	}
}
