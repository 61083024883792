.config-header {
	display: flex;
	flex-direction: row;
	margin-left: 0;
	margin-right: auto;
	width: 100%;
}

.expand-collapse-button {
	display: flex;
	background-color: var(--input-grey);
	color: var(--text);
	align-items: center;
	justify-content: center;
	padding-top: 3px;
	padding-bottom: 3px;
	cursor: pointer;
	border: 2px solid var(--border);
	border-radius: 10px;
}

@media screen and (max-width: 600px) {
	.expand-collapse-icon {
		margin: 0;
		transform: scale(0.8);
	}
}

@media screen and (max-width: 500px) {
	.expand-collapse-button {
		padding-top: 2px;
		padding-bottom: 2px;
	}
	.expand-collapse-icon {
		transform: scale(0.7);
	}
}

@media screen and (max-width: 400px) {
	.expand-collapse-button {
		padding: 0;
	}
	.expand-collapse-icon {
		transform: scale(0.6);
	}
}

.expand-collapse-button:hover {
	border: 2px solid var(--bluple-hover);
}

.config-name-input {
	background-color: var(--input-grey);
	color: var(--text);
	font-size: 1rem;
	border: 2px solid var(--border);
	border-radius: 10px;
	padding: 2px;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 10px;
	margin-right: 0;
	width: 200px;
}

@media screen and (max-width: 600px) {
	.config-name-input {
		font-size: 0.9rem;
		padding: 1px;
		padding-left: 9px;
		padding-right: 9px;
		width: 180px;
	}
}

@media screen and (max-width: 500px) {
	.config-name-input {
		font-size: 0.8rem;
		padding: 0;
		padding-left: 8px;
		padding-right: 8px;
		width: 140px;
	}
}

@media screen and (max-width: 400px) {
	.config-name-input {
		font-size: 0.7rem;
		padding: 0;
		padding-left: 7px;
		padding-right: 7px;
		width: 130px;
	}
}

.config-name-input:focus {
	border: 2px solid var(--bluple-hover);
	outline: none;
}

.config-name-input:hover {
	border: 2px solid var(--bluple-hover);
}

.config-name-input.invalid {
	border: 2px solid var(--red);
}
