.spinner-container {
	position: relative;
	display: flex;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	align-items: center;
	justify-content: center;
}

.spinner {
	border: 6px solid var(--grey);
	border-top: 6px solid var(--bluple);
	border-radius: 50%;
	width: 17px;
	height: 17px;
	animation: spin 1.5s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 600px) {
	.spinner {
		border: 4px solid var(--grey);
		border-top: 4px solid var(--bluple);
		width: 16px;
		height: 16px;
	}
}

@media screen and (max-width: 500px) {
	.spinner {
		width: 11px;
		height: 11px;
	}
}

@media screen and (max-width: 400px) {
	.spinner {
		width: 6px;
		height: 6px;
	}
}
