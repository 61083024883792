.config-footer {
	display: flex;
	flex-direction: row;
	margin-right: 0;
	margin-left: auto;
}

.button {
	position: relative;
	color: var(--text);
	align-content: center;
	border: none;
	border-radius: 10px;
	padding: 7.5px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 1.5rem;
	margin-left: 10px;
	margin-right: 0;
	margin-top: auto;
	margin-bottom: 0;
	cursor: pointer;
}

.button:focus {
	outline: none;
}

.delete {
	background-color: var(--red);
	width: 88px;
}

.delete:hover {
	background-color: var(--red-hover);
}

.save {
	background-color: var(--green);
	width: 71px;
}

.save:hover {
	background-color: var(--green-hover);
}

.save:disabled {
	background-color: var(--green-disabled);
	cursor: default;
}

.save:disabled:hover {
	background-color: var(--green-disabled);
}

@media screen and (max-width: 600px) {
	.save {
		width: 62px;
	}
	.delete {
		width: 77px;
	}
	.button {
		font-size: 1.25rem;
	}
}

@media screen and (max-width: 500px) {
	.save {
		width: 49px;
	}
	.delete {
		width: 61px;
	}
	.button {
		font-size: 1rem;
		border-radius: 7.5px;
		padding: 5px;
		padding-left: 7.5px;
		padding-right: 7.5px;
	}
}

@media screen and (max-width: 400px) {
	.save {
		width: 36px;
	}
	.delete {
		width: 46px;
	}
	.button {
		font-size: 0.75rem;
		border-radius: 5px;
		padding: 2.5px;
		padding-left: 5px;
		padding-right: 5px;
	}
}
