.explanation-container {
	display: flex;
	flex-direction: column;
	background-color: var(--input-grey);
	border: 2px solid var(--border);
	border-radius: 20px;
	margin: 20px auto 20px 20px;
	padding: 10px 20px 10px 20px;
}

.hide {
	display: none;
}

.explanation-item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

img {
	width: 20px;
	height: 20px;
}

.text {
	color: var(--text);
	font-size: 1rem;
	margin-left: 20px;
	margin-right: 30px;
}

@media screen and (max-width: 600px) {
	img {
		width: 15px;
		height: 15px;
	}
	.text {
		font-size: 0.8rem;
	}
}

@media screen and (max-width: 450px) {
	img {
		width: 10px;
		height: 10px;
	}
	.text {
		font-size: 0.6rem;
	}
}
